import {
  FullPage,
  PageContent,
  Body,
  ButtonGrid,
  HeadingGrid,
  GridImage,
  MenuImageGrid,
} from "./Components/GlobalElements";
import { Typography, Divider, Box, Button } from "@mui/material";
import React from "react";
import StickyFooter from "./Components/StickyFooter";
import { takeawayImages } from "./Data/takeawayImages";

const MenuPage = () => {
  return (
    <div>
      <FullPage>
        <PageContent>
          <Body>
            <HeadingGrid>
              <Typography
                variant="h4"
                align="center"
                sx={{ color: "yellow", textShadow: "3px 3px 2px black" }}
              >
                Golden Dragon Menu
              </Typography>
              <Typography
                variant="h4"
                align="center"
                sx={{ color: "red", textShadow: "3px 3px 2px black" }}
              >
                We are currently closed for holidays until Thursday night the 27th of February. Thank You !
              </Typography>
              <Divider sx={{ bgcolor: "white" }} />
              <Divider sx={{ bgcolor: "white" }} />
              <Typography variant="h7" align="center">
                Prices subject to change without notice.
              </Typography>
              <Divider sx={{ bgcolor: "white" }} />
            </HeadingGrid>
            <Box mt={3}>
              <ButtonGrid>
                <Button
                  size="large"
                  variant="contained"
                  style={{backgroundColor: "yellow"}}
                > <a href="DineIn.pdf"  target="_blank" style={{ color: "black", textDecoration: "none"}}>
                  Dine In Menu
                </a>
                </Button>
                <Button
                  size="large"
                  variant="contained"
                  style={{backgroundColor: "yellow",}}
                > <a href="takeaway.pdf"  target="_blank" style={{ color: "black", textDecoration: "none"}}>
                  Takeaway Menu
                </a>
                </Button>
              </ButtonGrid>
              <MenuImageGrid>
                {takeawayImages.map((item) => (
                  <GridImage
                    src={`${item.img}`}
                    srcSet={`${item.img}`}
                    alt={item.title}
                    loading="eager"
                  />
                ))}
              </MenuImageGrid>
            </Box>
          </Body>
        </PageContent>
        
      </FullPage>
      <StickyFooter />
    </div>
  );
};

export default MenuPage;
