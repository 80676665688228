import {
  FullPage,
  PageContent,
  Body,
  TableGrid,
  HeadingGrid,
  ImageGrid,
  GridImage,
} from "./Components/GlobalElements";
import {
  Typography,
  Button,
  Box,
  Divider,
} from "@mui/material";
import { Link } from 'react-router-dom';
import { qualityImageList } from "./Data/qualityimages";
import StickyFooter from "./Components/StickyFooter";



const HomePage = () => {
  return (
    <div>
      <FullPage>
        <PageContent>
          <Body>
            <HeadingGrid>
              <Typography
                variant="h4"
                align="center"
                sx={{ color: "yellow", textShadow: "3px 3px 2px black" }}
              >
                Welcome to Caloundra's Golden Dragon <br />
                Chinese Restaurant
              </Typography>
              <Typography
                variant="h4"
                align="center"
                sx={{ color: "red", textShadow: "3px 3px 2px black" }}
              >
                We are currently closed for holidays until Thursday night the 27th of February.
                Happy Chinese New Year! 新年快乐！
              </Typography>
              <Divider sx={{ bgcolor: "white" }} />
              <Divider sx={{ bgcolor: "white" }} />
              <Typography variant="h5" align="center">
                BYO
              </Typography>
              <Typography variant="h5" align="center">
                金龍中餐廳
              </Typography>
              <Typography variant="h6" align="center">
                Open for dinner every day from 4:45pm till late
              </Typography>
            </HeadingGrid>
            <Box mt={3}>
              <TableGrid>
                  <Button size="large" style={{backgroundColor: "yellow", color: "black"}} variant="contained" component={Link} to={"/menu"}>
                    View Menu
                  </Button>
                  <Button size="large" style={{backgroundColor: "yellow", color: "black"}} variant="contained" component={Link} to={"/booking"}>
                    Make Booking
                  </Button>
                  <Button size="large" style={{backgroundColor: "yellow", color: "black"}} variant="contained" component={Link} to={"/order"}>
                    Order Delivery
                  </Button>
                  <Button size="large" style={{backgroundColor: "yellow", color: "black"}} variant="contained" component={Link} to={"/about"}>
                    About Us
                  </Button>
              </TableGrid>
            </Box>
            <ImageGrid>
              {qualityImageList.map((item) => (
                <GridImage
                  src={`${item.img}`}
                  srcSet={`${item.img}`}
                  alt={item.title}
                  loading="eager"
                />
              ))}
            </ImageGrid>
          </Body>
        </PageContent>

      </FullPage>
      <StickyFooter />
    </div>
  );
};

export default HomePage;
